<template>
  <div>
    <div v-for="(item, index) in List_Data" :key="index" class="entirety">
      <div class="title">
        {{ item.content }}
      </div>
      <div class="content">
        <el-table
          :data="item.items"
          style="width: 100%"
          :show-header="index != 1"
        >
          <el-table-column prop="content" label="检查项目"> </el-table-column>
          <el-table-column prop="status" label="检查结果">
            <template slot-scope="scope">
              {{ ["未检查", "正常", "异常"][scope.row.status] }}
            </template>
          </el-table-column>
          <el-table-column prop="detail" label="检查说明"> </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import { inspectiontaskInfo } from "../../assets/request/api";
export default {
  data() {
    return {
      List_Data: [],
    };
  },
  created() {
    let { instance_id } = this.$route.query;
    inspectiontaskInfo({ instance_id }).then((res) => {
      this.List_Data = res.data;
      console.log(res);
    });
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.entirety {
  background: #ffffff;
  display: flex;
}
.title {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ebeef5;
}
.content {
  flex: 3;
  // & > div {
  //   display: flex;
  //   p {
  //     flex: 1;
  //   }
  // }
}
</style>